import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Box, Heading, Flex, Text, Icon } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
// import { CiLinkedin } from 'react-icons/ci';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import styles from './App.module.css';

const App = () => {
  return (
    <StrictMode>
      <ChakraProvider>
        <Flex bg="#c4b3b3">
          <Box as="article">
            <InfoOutlineIcon
              w={[20, 40, 40]}
              h={[20, 40, 40]}
              mb="2.5em"
              color="gray.400"
            />
            <Heading
              as="h1"
              fontWeight={['light', 'normal', 'medium']}
              mb={['1em', '1em', '2em']}
            >
              We&rsquo;ll be back soon!
            </Heading>
            <Box>
              <Text>
                Please get to know a bit about what I do as a software developer
                💻 and watch this YouTube video {}
                <Box as="a" href="https://linktr.ee/MozesElbarbary">
                  here
                </Box>
              </Text>
              <br />
              <Text>
                And if you wanna have a peak over the artistry side of Mozaick
                🎶 check out my music library {}
                <Box as="a" href="https://linktr.ee/Mozaick">
                  here
                </Box>
              </Text>
              <br />
              <Text>
                Apologies for the inconvenience. We&rsquo;re performing some
                maintenance 🛠 at the moment. Thank you for visiting us.
              </Text>
              <Text>&mdash; MOZAICK.NET</Text>
              <br />
              <Flex
                style={{
                  display: 'flex',
                  borderRadius: '10px',
                  paddingTop: '15.66px',
                  flexWrap: 'wrap',
                  justifyContent:"center"
                  // boxShadow: '5px 10px 18px #888888',
                  // opacity: '0.5'
                  // filter: 'blur(1px)'
                  // borderRight: '1px solid #888888',
                  // borderLeft: '1px solid #888888'
                  // textAlign: 'center',
                }}
                className={styles.icon_container}
              >
                <Box as="a" href="https://www.linkedin.com/in/mozeselbarbary">
                  <Icon as={FaLinkedin} w={6} h={6} color="black" />
                </Box>
                <Box as="a" href="https://www.youtube.com/@forcode2022">
                  <Icon as={FaYoutube} w={6} h={6} color="black" />
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </ChakraProvider>
    </StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
