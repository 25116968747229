.icon_container > * {
  /* border-top: 1px solid green; */
  /* box-shadow: 5px 10px 18px #888888 */
  /* box-shadow: 5px 10px 18px #888888 inset*/
  box-shadow: 1px 1px 6px 2px #9b8a8a;
  padding-top: .5em;
  border-radius: 5px;
}

.icon_container > :last-child {
  flex: 0 0 30px;
  /* align-self: stretch; */
  /* border: 1px solid red */
}
.icon_container > :first-child {
  flex: 0 0 30px;
}
